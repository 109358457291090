import { Box, FormHelperText, Grid, GridSize, Typography } from '@mui/material';
import React from 'react';
import { useStyles } from './styles';

interface PaymentFieldProps {
  error?: string;
  onChange: (event: any) => void;
  label: string;
  options: any;
  Component: React.ComponentType<any>;
  xs: GridSize;
}

const PaymentField = ({ error, onChange, label, options, Component, xs }: PaymentFieldProps) => {
  const { classes } = useStyles();
  
  return (
    <Grid item xs={xs} className={classes.container}>
      <Typography className={classes.formFieldLabel}>
        {label}
      </Typography>
      <Box
        className={classes.field}
        style={{
          ...(error && {
            border: '1px solid red'
          })
        }}
        tabIndex={-1}
      >
        <Component options={options} onChange={onChange}/>
      </Box>
      
      {error && (
        <FormHelperText error className={classes.errorText}>
          {error}
        </FormHelperText>
      )}
    </Grid>
  );
};

export default PaymentField;