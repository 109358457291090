
export type Donor = {
  amount: number
  currency: string
  datetime: string
  message: string
  name: string
  type: 'self' | 'anon' | 'other'
}

export type IEvent = {
  donations: {
    charity: {
      name: string
      url: string
    }
    description: string
    enabled: boolean
    end_time: string
    goal: number
    image_url: string
    payee_id: string
    payee_name: string
    start_time: string,
    fee_allocation: string,
  }
  currency: string
  currencySymbol: string
  hometown: string
  id: number
  image_url: string
  local_end_date: string
  local_start_date: string
  name: string
  reg_closing_date: string
  reg_opening_date: string
  site_uri: string
}

export enum PaymentProcessor {
  STRIPE = 'stripe_us',
  STRIPE_EU = 'stripe_eu',
  ADYEN = 'adyen',
  ADYEN_EU = 'adyen_eu',
  ADYEN_MX = 'adyen_mx',
}