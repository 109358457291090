import { combineReducers, configureStore } from "@reduxjs/toolkit";
import darkMode from "./reducers/darkMode";
import charities from "./reducers/eventCharity";
import session from "./reducers/session";

export default configureStore({
  reducer: combineReducers({
    darkMode,
    charities,
    session,
  })
});
