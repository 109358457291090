import CircularProgress from '@mui/material/CircularProgress';
import React from 'react'
import { BAZU_STATIC_URL } from './env'
import { useEventId } from './location'
import NotFoundPage from './NotFoundPage'

function EventPage() {
  const eventId = useEventId()
  const [loading, setLoading] = React.useState(false)
  const [notFound, setNotFound] = React.useState(false)

  React.useEffect(() => {
    if (!eventId) {
      setNotFound(true)
      return
    }
    setLoading(true)

    fetch(BAZU_STATIC_URL + `/event-donations/${eventId}.json`)
      .then(res => {
        if (res.status !== 200) {
          console.log('status', res.statusText)
          setNotFound(true)
        }
        else return res.json().then(json => {
          if (!Array.isArray(json) || !json.length) {
            setNotFound(true)
          }
          else window.location.href = `/event/${eventId}/charity/${json[0].id}`
        })
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false))
    return
  }, [eventId])

  if (loading) {
    return <CircularProgress style={{ display: 'block', margin: '0 auto' }} />
  }
  else if (notFound) {
    return <NotFoundPage />
  }
  return (
    <>
    </>
  )
}

export default EventPage
