
export const formatAsCurrency = (value: number, currency: string, wholeNumber = false) => {
  return value.toLocaleString([], {
    currency,
    maximumFractionDigits: wholeNumber ? 0 : 2,
    minimumFractionDigits: wholeNumber ? 0 : 2,
    style: 'currency',
  })
}

export const getCurrencySymbol = (currency: string) => {
  switch (currency) {
    case 'BRL':
      return 'R$'
    case 'BTC':
    case 'XBT':
      return '₿'
    case 'ETH':
      return 'Ξ'
    case 'EUR':
      return '€'
    case 'GBP':
      return '£'
    case 'KRW':
      return '₩'
    case 'CNY':
    case 'JPY':
      return '¥'
    case 'USD':
    default:
      return '$'
  }
}
