import React from 'react'
import useEventCharity from '../useEventCharity'
import { Helmet } from 'react-helmet';
import env, { Env }from '../env'

const Tealium = () => {

  const { charity } = useEventCharity()
  const [tealiumKey, setTealiumKey] = React.useState('')

  const currentEnv: Env = env()

  React.useEffect(() => {
    if (charity) {
      if (charity.tealiumTagManagerKey) {
        setTealiumKey(charity.tealiumTagManagerKey)
      }
    }
  }, [charity])

  return (
    tealiumKey
    ? <Helmet>
        <script>
        {
          `
            (function(a,b,c,d){
            a='//tags.tiqcdn.com/utag/${tealiumKey}/main/${currentEnv === Env.Prod ? 'prod' : 'dev'}/utag.js';
            b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
            a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
            })();
          `
        }
        </script>
      </Helmet>
    : <></>
  )
}

export default Tealium
