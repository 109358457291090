import { useSelector } from 'react-redux'
import { getCurrencySymbol } from './currency'
import { useCharityId, useEventId } from './location'
import { EventCharityState, EventCharity } from './reducers/eventCharity'

export default function useEventCharity() {
  const eventId = useEventId()
  const charityId = useCharityId()

  const state = useSelector<{ charities: EventCharityState }, EventCharity>(_ => {
    return _.charities[charityId] || {
      charity: {
        customDonations: [],
        description: '',
        donors: [],
        endTime: '',
        feeAllocation: '',
        goal: 0,
        imageUrl: '',
        name: '',
        startTime: '',
        url: '',
      },
      charityId: 0,
      currencyId: 'USD',
      event: {
        hometown: '',
        imageUrl: '',
        localEndDate: '',
        localStartDate: '',
        name: '',
        regClosingDate: '',
        registerUrl: '',
        regOpeningDate: '',
        url: '',
      },
      eventId,
      paymentProcessor: '',
    }
  })
  return {
    ...state,
    currencySymbol: getCurrencySymbol(state.currencyId)
  }
}
