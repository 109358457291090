import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaymentProcessor } from '../types';

export enum EventDonationFeeAllocationEnum {
  EVENT = 'event',
  ATHLETE = 'athlete'
}

export type EventCharity = {
  charity: {
    customDonations?: {
      name: string
      value: string
    }[]
    description: string
    endTime: number
    feeAllocation: EventDonationFeeAllocationEnum
    goal: string
    imageUrl: string
    name: string
    startTime: number
    url: string
    orgGoogleTagManagerKey?: string
    tealiumTagManagerKey?: string
  }
  charityId: number
  currencyId: string
  event: {
    hometown: string
    localEndDate: string
    localStartDate: string
    imageUrl: string
    name: string
    eventGoogleTagManagerKey?: string
    regClosingDate: string
    regOpeningDate: string
    registerUrl: string
    url: string
  }
  eventId: number
  paymentProcessor: PaymentProcessor
}

export type EventCharityState = { [key: string]: EventCharity }

const slice = createSlice({
  name: 'event_charities',
  initialState: {} as EventCharityState,
  reducers: {
    cacheEventCharity: (state, action: PayloadAction<EventCharity>) => {
      state[action.payload.charityId] = action.payload
    }
  },
})

export const cacheEventCharity = slice.actions.cacheEventCharity

export default slice.reducer
