
export enum Env {
  Dev,
  Stage,
  Prod
}

export default function env(): Env {
  if (window.location.href === 'https://donate.chronotrack.com') {
    return Env.Prod
  }
  else if (!window.location.href.indexOf('http://local')) {
    return Env.Dev
  }
  return Env.Stage
}

export const BAZU_STATIC_URL = process.env.REACT_APP_BAZU_STATIC_URL
