import React from 'react'
import {
  Typography,
  Box,
  Link,
  LinearProgress,
  Button,
} from '@mui/material'
import Alert from '@mui/material/Alert'
import { useHistory } from 'react-router-dom'
import { useEventId, useCharityId } from './location'
import useEventCharity from './useEventCharity'
import { formatAsCurrency } from './currency'
import { useDispatch } from 'react-redux'
import { CTLIVE_API_URL } from './DonatePage'
import { cacheToken } from './reducers/session'
import { monthsDiff } from './dates'

function CharityInfo({
  donors,
}: {
  donors: {
    amount: number
  }[]
}) {
  const dispatch = useDispatch()
  const history = useHistory()
  const eventId = useEventId()
  const charityId = useCharityId()
  const { charity, currencyId } = useEventCharity()
  const raised = donors.reduce((acc, x) => acc + +x.amount, 0)
  const hasEnded =
    !!charity.endTime && new Date(charity.endTime) < new Date()
  const notStarted =
    !!charity.startTime && new Date(charity.startTime) > new Date()
  const disabled = hasEnded || notStarted
  const progress = (raised / +charity.goal) * 100
  return (
    <>
      <Box>
        <Typography
          style={{
            fontSize: 24,
            fontWeight: 900,
            lineHeight: '27px',
          }}
        >
          {charity.name}
        </Typography>
        {!charity.url ? null : (
          <Link
            href={`${charity.url.indexOf('http') !== 0 ? 'http://' : ''
              }${charity.url}`}
            target="_blank"
            style={{
              fontSize: 18,
              lineHeight: '27px',
            }}
          >
            {charity.url}
          </Link>
        )}
      </Box>
      <Box marginTop={2}>
        <Typography
          style={{
            fontWeight: 300,
            fontSize: 24,
            lineHeight: '28wpx',
          }}
        >
          Goal <strong>{formatAsCurrency(raised, currencyId, true)}</strong> of{' '}
          {formatAsCurrency(+charity.goal, currencyId, true)} raised
        </Typography>
        <LinearProgress
          style={{
            height: 10,
            marginBottom: 15,
            marginTop: 15,
          }}
          value={progress > 100 ? 100 : progress}
          variant="determinate"
        />
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography
            style={{
              fontSize: 12,
              lineHeight: '14px',
            }}
          >
            {!raised
              ? 'be the first to donate!'
              : `by ${donors.length} people${!charity.startTime || notStarted
                ? ''
                : ` in ${monthsDiff(
                  new Date(charity.startTime),
                  new Date()
                )} months`
              }`}
          </Typography>
          {!charity.endTime ? null : (
            <Typography
              style={{
                fontSize: 12,
                lineHeight: '14px',
              }}
            >
              Collection End Date:{' '}
              <span style={{ color: '#00B0FF' }}>
                {new Date(charity.endTime).toLocaleDateString()}
              </span>
            </Typography>
          )}
        </Box>
      </Box>
      <Box marginTop="30px">
        <Typography
          style={{
            fontSize: 24,
            fontWeight: 300,
            lineHeight: '28px',
          }}
        >
          Donate
        </Typography>
        {!disabled
          ? null
          : (
            <Alert severity="info">
              <Typography>
                {hasEnded
                  ? 'Giving has ended for this event'
                  : notStarted
                    ? `Giving starts on ${new Date(
                      charity.startTime
                    ).toLocaleDateString()}`
                    : 'Giving is disabled'}
              </Typography>
            </Alert>
          )}
        <Button
          color="primary"
          data-cy="donate"
          disabled={disabled}
          onClick={async () => {
            // TODO: loading
            const response = await fetch(
              CTLIVE_API_URL + '/registration/createAnonymousSession',
              {
                method: 'POST',
                body: JSON.stringify({ clientId: 'donate' }),
              }
            )
            if (response.status === 200) {
              const token = await response.text()
              dispatch(cacheToken(token))
              history.push('/donate/' + eventId + '/charity/' + charityId)
            }
            // TODO: handle error
          }}
          style={{
            fontSize: 12,
            height: 45,
            lineHeight: '14px',
            marginTop: 18,
            width: 245,
          }}
          variant="contained"
        >
          Donate
        </Button>
      </Box>
    </>
  )
}

export default CharityInfo
