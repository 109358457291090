import { useState, useEffect } from 'react'
import { loadStripe, Stripe } from '@stripe/stripe-js'

import { PaymentProcessor } from './types';

function useStripeInstance(
  paymentProcessor?: PaymentProcessor
): Promise<Stripe | null> | null {
  const [stripeInstance, setStripeInstance] =
    useState<Promise<Stripe | null> | null>(null)
  
  useEffect(() => {
    let stripeKey: string | null = null
    
    if (paymentProcessor === PaymentProcessor.STRIPE) {
      stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!
    } else if (paymentProcessor === PaymentProcessor.STRIPE_EU) {
      stripeKey = process.env.REACT_APP_STRIPE_EU_PUBLISHABLE_KEY!
    }
    
    if (stripeKey) {
      const stripe = loadStripe(stripeKey)
      setStripeInstance(stripe)
    }
  }, [paymentProcessor])
  
  return stripeInstance
}

export default useStripeInstance