import { useParams } from 'react-router-dom'

export function getSearchParam(key: string) {
  return new URLSearchParams(window.location.search).get(key) || ''
}

export function useEventId() {
  const {
    eventId = getSearchParam('eventID')
  } = useParams<{ eventId: string }>()

  return +eventId
}

export function useCharityId() {
  const {
    charityId = getSearchParam('charityID')
  } = useParams<{ charityId: string }>()

  return +charityId
}
