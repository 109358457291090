import React from 'react'
import { useSelector } from 'react-redux'

function CTLiveLogo () {
  const darkMode = useSelector(state => state.darkMode)
  const chronoColor = darkMode ? '#fff' : '#2C314E'
  return (
    <svg width="207px" height="50px" viewBox="0 0 207 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>F0448D50-2D54-4E53-B208-8F0FFB342A17</title>
      <desc>Created with sketchtool.</desc>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="My-Events-2.0-LIGHT-MODE--NAV-1440px-" transform="translate(-246.000000, -25.000000)">
          <g id="Group-2" transform="translate(246.671215, 24.822581)">
            <path d="M4.06381798,7.92617132 L7.10454822,11.1659896 C5.274802,14.6118283 4.72512841,19.0407542 5.82918624,23.9771734 C8.52201023,36.0172203 20.0832012,46.4758879 31.6084879,47.3384584 C38.7534476,47.877565 44.1749999,44.6429255 46.6883022,39.2878002 C44.2109042,45.7930196 38.0353612,49.8183487 29.7773676,49.1714208 C17.139047,48.2010289 4.53663073,36.7719695 1.5924765,23.6537095 C0.190941318,17.3917839 1.23033348,11.8791624 4.06381798,7.92617132 Z M8.88067785,13.0593277 L11.5164544,15.8686048 C10.9374499,18.0504471 10.8978701,20.5575907 11.5020688,23.2583647 C13.6204237,32.6028786 22.5965036,40.7254177 31.5366793,41.4082861 C37.0659445,41.8395713 41.3026543,39.3237406 43.2414875,35.1546498 C41.3026543,40.2222516 36.5273797,43.3131293 30.1005065,42.8099632 C20.3345315,42.055214 10.5326521,33.1779257 8.23477567,23.0067816 C7.38565385,19.2482939 7.67885595,15.8333389 8.88067785,13.0593277 Z M21.8066086,5.93507331 C28.1971901,6.43820895 34.5877716,10.3912084 38.8962813,15.9255087 C34.983494,11.3261391 29.4542287,8.05555918 23.9249635,7.62427392 C19.1076939,7.25632133 15.2703302,9.1214624 13.0813061,12.376286 L10.6255146,10.1982182 C13.0900433,7.21774638 16.9992187,5.56490209 21.8066086,5.93507331 Z M19.0778803,1.6222207 C27.3358738,2.26914859 35.5938674,7.37269085 41.1231327,14.4888977 C36.0965279,8.55872532 28.9515682,4.35369402 21.8425129,3.81458745 C16.2909701,3.38313345 11.7577233,5.23893498 8.84305691,8.61794877 L5.79658074,5.91676929 C8.97942746,2.83853964 13.5730988,1.19835 19.0778803,1.6222207 Z" id="Combined-Shape" fill={chronoColor} fillRule="nonzero"></path>
            <path d="M53.497013,19.844023 L51.4248969,21.8207471 C49.9958513,20.3112487 48.4239012,19.5564995 46.6733203,19.5564995 C45.1728225,19.5564995 43.9224076,20.0596656 42.9220757,21.1019383 C41.8860176,22.1442111 41.3858516,23.4021264 41.3858516,24.8756844 C41.3858516,25.9179571 41.6002085,26.8524085 42.0646483,27.6430981 C42.5290881,28.4697282 43.1721586,29.0807157 43.9938598,29.5479414 C44.8155611,30.0151671 45.7444407,30.2308097 46.7447726,30.2308097 C47.6021999,30.2308097 48.388175,30.0511075 49.1026978,29.763584 C49.8172206,29.4401201 50.6031957,28.865073 51.460623,28.0025025 L53.4612869,30.1229884 C52.3180504,31.237142 51.2105401,32.0278316 50.2102081,32.4591169 C49.1741501,32.8904022 48.0309136,33.1060448 46.7090465,33.1060448 C44.279669,33.1060448 42.3147313,32.3512956 40.7427811,30.8058567 C39.2065571,29.2604179 38.4205821,27.2836938 38.4205821,24.8756844 C38.4205821,23.3302455 38.7778435,21.9285684 39.4923662,20.7065935 C40.206889,19.4846186 41.207221,18.5142268 42.5290881,17.7594776 C43.8509553,17.0047284 45.2442747,16.645324 46.7804987,16.645324 C48.0666398,16.645324 49.3170547,16.9328475 50.4960173,17.4719541 C51.6035276,18.0470011 52.6395856,18.8376907 53.497013,19.844023 Z" id="Path-Copy-103" fill={chronoColor} fillRule="nonzero"></path>
            <polygon id="Path-Copy-105" fill={chronoColor} fillRule="nonzero" points="56.4150319 17.0766092 59.2772925 17.0766092 59.2772925 23.1457309 64.2521739 23.1457309 64.2521739 17.0766092 67.1144345 17.0766092 67.1144345 33.1505861 64.2521739 33.1505861 64.2521739 26.1251179 59.2772925 26.1251179 59.2772925 33.1505861 56.4150319 33.1505861"></polygon>
            <path d="M73.6105003,17.0766092 C75.2779397,17.0766092 76.4937809,17.2237395 77.1885473,17.5547825 C77.8833137,17.8858255 78.4738652,18.4007812 78.890725,19.1364324 C79.3075848,19.8720835 79.5507531,20.7548648 79.5507531,21.7479938 C79.5507531,22.8146879 79.3075848,23.6974693 78.8212484,24.3963378 C78.3349119,25.0952064 77.6401455,25.6469447 76.6674725,26.0147703 L80.2455195,33.1505861 L77.0843324,33.1505861 L73.679977,26.3458133 L73.4020704,26.3458133 L73.4020704,33.1505861 L70.5187899,33.1505861 L70.5187899,17.0766092 L73.6105003,17.0766092 Z M74.2357901,20.0559962 L73.4368087,20.0559962 L73.4368087,23.3664263 L74.3400051,23.3664263 C75.2779397,23.3664263 75.9032295,23.2560786 76.2506127,22.9986007 C76.5979959,22.7411228 76.7716875,22.3365147 76.7716875,21.7112112 C76.7716875,21.3433857 76.7022108,21.0491252 76.5285192,20.7916474 C76.3548276,20.5341695 76.1116594,20.3502567 75.8337528,20.239909 C75.5558463,20.1295614 75.0000332,20.0559962 74.2357901,20.0559962 Z" id="Combined-Shape-Copy-48" fill={chronoColor} fillRule="nonzero"></path>
            <path d="M90.2507678,16.6812644 C92.4424957,16.6812644 94.3160695,17.4719541 95.8714892,19.0892738 C97.426909,20.7065935 98.2399693,22.6473772 98.2399693,24.9475653 C98.2399693,27.2477533 97.4622594,29.188537 95.9068397,30.7699163 C94.3514199,32.3512956 92.4778461,33.1419852 90.2507678,33.1419852 C87.9529886,33.1419852 86.0087139,32.3153551 84.4886446,30.6980354 C82.9685753,29.0807157 82.1908654,27.139932 82.1908654,24.9116248 C82.1908654,23.4021264 82.5443699,22.0363897 83.2513789,20.7784744 C83.9583879,19.5205591 84.9482004,18.5142268 86.1854662,17.795418 C87.4580823,17.0766092 88.8013994,16.6812644 90.2507678,16.6812644 Z M90.2154174,19.6283804 C88.8013994,19.6283804 87.5994841,20.1315465 86.6096716,21.1378788 C85.619859,22.1442111 85.1603031,23.4380668 85.1603031,24.9835057 C85.1603031,26.7086467 85.7612608,28.0743834 86.9985265,29.1166561 C87.9529886,29.9073458 89.0488525,30.3026906 90.2861183,30.3026906 C91.6647858,30.3026906 92.866701,29.7995244 93.8565136,28.7572517 C94.8463262,27.7509195 95.3412325,26.4570637 95.3412325,24.9835057 C95.3412325,23.4740073 94.8463262,22.2160919 93.8565136,21.2097597 C92.8313506,20.1315465 91.6294353,19.6283804 90.2154174,19.6283804 Z" id="Combined-Shape-Copy-49" fill={chronoColor} fillRule="nonzero"></path>
            <polygon id="Path-Copy-106" fill={chronoColor} fillRule="nonzero" points="100.709169 17.0766092 103.673043 17.0766092 110.688794 27.6332027 110.688794 17.0766092 113.802737 17.0766092 113.802737 33.1505861 110.801346 33.1505861 103.785595 22.6307751 103.785595 33.1505861 100.671652 33.1505861 100.671652 17.0766092"></polygon>
            <path d="M124.538561,16.6812644 C126.796705,16.6812644 128.727054,17.4719541 130.329607,19.0892738 C131.932161,20.7065935 132.76986,22.6473772 132.76986,24.9475653 C132.76986,27.2477533 131.968583,29.188537 130.366029,30.7699163 C128.763475,32.3512956 126.833127,33.1419852 124.538561,33.1419852 C122.171152,33.1419852 120.16796,32.3153551 118.601828,30.6980354 C117.035696,29.0807157 116.234419,27.139932 116.234419,24.9116248 C116.234419,23.4021264 116.598636,22.0363897 117.327069,20.7784744 C118.055503,19.5205591 119.07531,18.5142268 120.350068,17.795418 C121.661249,17.0766092 123.045272,16.6812644 124.538561,16.6812644 Z M124.502139,19.6283804 C123.045272,19.6283804 121.806935,20.1315465 120.787128,21.1378788 C119.803743,22.1442111 119.29384,23.4380668 119.29384,24.9835057 C119.29384,26.7086467 119.913008,28.0743834 121.187767,29.1166561 C122.171152,29.9073458 123.300224,30.3026906 124.574983,30.3026906 C125.995428,30.3026906 127.233765,29.7995244 128.253572,28.7572517 C129.273379,27.7509195 129.783282,26.4570637 129.783282,24.9835057 C129.783282,23.4740073 129.273379,22.2160919 128.253572,21.2097597 C127.197343,20.1315465 125.959006,19.6283804 124.502139,19.6283804 Z" id="Combined-Shape-Copy-50" fill={chronoColor} fillRule="nonzero"></path>
            <polygon id="Path-Copy-107" fill="#FF5722" fillRule="nonzero" points="135.201542 18.5083642 135.201542 16.9103552 143.955599 16.9103552 143.955599 18.5083642 140.402697 18.5083642 140.402697 33.1505861 138.791071 33.1505861 138.791071 18.5083642"></polygon>
            <path d="M153.682328,17.1333332 C154.634083,17.3563112 155.402809,17.8394302 155.988504,18.5455272 C156.5742,19.2887872 156.867048,20.1806992 156.867048,21.2212632 C156.867048,22.1131752 156.647412,22.8935982 156.244746,23.5625321 C155.842081,24.2314661 155.256385,24.7517481 154.48766,25.0862151 C153.718934,25.4206821 152.693967,25.6064971 151.339546,25.6064971 L157.086684,33.1505861 L155.109961,33.1505861 L149.362824,25.6064971 L148.447674,25.6064971 L148.447674,33.1505861 L146.873617,33.1505861 L146.873617,16.9103552 L150.094943,16.9103552 C151.85203,16.9103552 153.060027,16.9846812 153.682328,17.1333332 Z M148.520886,18.5083642 L148.520886,24.0084881 L151.266334,24.0456511 C152.327907,24.0456511 153.133239,23.9341621 153.645722,23.7483471 C154.158206,23.5253691 154.560872,23.2280652 154.853719,22.7449462 C155.146567,22.2989902 155.292991,21.7787082 155.292991,21.2212632 C155.292991,20.6638182 155.146567,20.1806992 154.853719,19.7347432 C154.560872,19.2887872 154.194812,18.9543202 153.718934,18.7685052 L148.520886,18.5083642 Z" id="Combined-Shape-Copy-51" fill="#FF5722" fillRule="nonzero"></path>
            <path d="M165.127447,16.9103552 L172.649451,33.1134231 L170.907903,33.1134231 L168.388217,27.7991141 L161.422026,27.7991141 L158.90234,33.1134231 L157.086684,33.1134231 L164.71985,16.9103552 L165.127447,16.9103552 Z M164.905122,20.3293512 L162.126056,26.2011051 L167.647133,26.2011051 L164.905122,20.3293512 Z" id="Combined-Shape-Copy-52" fill="#FF5722" fillRule="nonzero"></path>
            <path d="M188.212218,19.9205582 L186.934304,20.8867962 C186.220175,19.9577212 185.355703,19.2887872 184.378475,18.8056682 C183.36366,18.3225492 182.273674,18.0995712 181.070931,18.0995712 C179.755431,18.0995712 178.552688,18.3968752 177.425116,19.0286462 C176.297544,19.6604172 175.433072,20.4780032 174.831701,21.5185672 C174.230329,22.5591312 173.892058,23.7483471 173.892058,25.0490521 C173.892058,27.0186911 174.568601,28.6538631 175.959273,29.9917311 C177.312359,31.3295991 179.078888,31.9613701 181.146102,31.9613701 C183.438832,31.9613701 185.355703,31.0694581 186.934304,29.2856341 L188.212218,30.2518721 C187.385333,31.2924361 186.370518,32.0728591 185.167775,32.6303041 C183.927446,33.1877491 182.57436,33.485053 181.070931,33.485053 C178.214416,33.485053 175.921687,32.5559781 174.267915,30.6606651 C172.877243,29.0626561 172.163115,27.1301801 172.163115,24.9004001 C172.163115,22.5219682 172.99,20.5151662 174.681358,18.9171572 C176.372716,17.2819852 178.477516,16.5015622 180.99576,16.5015622 C182.536774,16.5015622 183.927446,16.7988662 185.130189,17.3934742 C186.445689,18.0252452 187.422918,18.8428312 188.212218,19.9205582 Z" id="Path-Copy-108" fill="#FF5722" fillRule="nonzero"></path>
            <polygon id="Path-Copy-109" fill="#FF5722" fillRule="nonzero" points="191.16663 16.9103552 192.767901 16.9103552 192.767901 23.0050872 199.136593 16.9103552 201.24736 16.9103552 193.604929 24.1943031 201.82964 33.1505861 199.682481 33.1505861 192.731508 25.6064971 192.731508 33.1505861 191.130237 33.1505861 191.130237 16.9103552"></polygon>
            <polygon id="Path-Copy-111" fill="#FF5722" fillRule="nonzero" points="31.6248157 33.7350025 34.0435537 12.6278399 29.4530324 26.1405746 -2.72137868e-12 2.85387512e-14"></polygon>
            <path d="M204.539229,31.8392201 L204.678182,31.8392201 C204.817135,31.8392201 204.886612,31.7542732 204.886612,31.6693264 C204.886612,31.5843795 204.817135,31.4994326 204.678182,31.4994326 L204.539229,31.4994326 L204.539229,31.8392201 Z M204.539229,32.518795 L204.330799,32.518795 L204.330799,31.3295389 L204.678182,31.3295389 C204.886612,31.3295389 204.956089,31.3295389 205.025565,31.4144858 C205.095042,31.4994326 205.095042,31.5843795 205.095042,31.6693264 C205.095042,31.8392201 205.025565,31.924167 204.886612,31.924167 C204.956089,32.0091138 205.025565,32.0940607 205.095042,32.1790075 C205.095042,32.3489013 205.164519,32.4338481 205.164519,32.4338481 L204.956089,32.4338481 C204.956089,32.3489013 204.886612,32.2639544 204.886612,32.1790075 C204.886612,32.0091138 204.817135,32.0091138 204.678182,32.0091138 L204.539229,32.0091138 L204.539229,32.518795 Z M203.983416,31.924167 C203.983416,32.4338481 204.330799,32.8585825 204.747659,32.8585825 C205.164519,32.8585825 205.511902,32.4338481 205.511902,31.924167 C205.511902,31.4144858 205.233995,30.9897514 204.747659,30.9897514 C204.261322,30.9897514 203.983416,31.4144858 203.983416,31.924167 L203.983416,31.924167 Z M205.720332,31.924167 C205.720332,32.6037419 205.303472,33.1134231 204.747659,33.1134231 C204.191846,33.1134231 203.774986,32.6037419 203.774986,31.924167 C203.774986,31.244592 204.191846,30.7349108 204.747659,30.7349108 C205.233995,30.7349108 205.720332,31.244592 205.720332,31.924167 L205.720332,31.924167 Z" id="Fill-23-Copy-12" fill="#FF6B00"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default CTLiveLogo
