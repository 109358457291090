import React, { useEffect, useMemo, useState } from 'react';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent
} from '@stripe/stripe-js';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { Grid, GridSize, TextField, Typography } from '@mui/material';
import { useStyles, stripeStyles } from './styles';
import { useSelector } from 'react-redux';
import { IPayload, IStripePaymentData } from '../DonatePage';
import PaymentField from './PaymentField';

const fields: {
  label: string;
  type: 'cardNumber' | 'cardExpiry' | 'cardCvc';
  component: React.ComponentType;
  xs: GridSize;
}[] = [
  {
    label: 'Card Number:',
    type: 'cardNumber',
    component: CardNumberElement,
    xs: 12,
  },
  {
    label: 'Expiry date:',
    type: 'cardExpiry',
    component: CardExpiryElement,
    xs: 6,
  },
  {
    label: 'CVC:',
    type: 'cardCvc',
    component: CardCvcElement,
    xs: 6,
  },
]

const StripePayment = ({ setPayload }: {
  setPayload: React.Dispatch<React.SetStateAction<IPayload<IStripePaymentData>>>
}) => {
  const darkMode = useSelector<{
    darkMode: boolean
  }, boolean>(state => state.darkMode);
  const { classes } = useStyles();
  const [value, setValue] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
    holderName: '',
  });
  const [fieldErrors, setFieldErrors] = useState({
    cardNumber: '',
    cardExpiry: '',
    cardCvc: '',
    holderName: '',
  });
  
  useEffect(() => {
    const isAllFieldsValid = Object.values(value).every(Boolean);
    setPayload(prev => ({
      ...prev,
      paymentData: isAllFieldsValid ? {
        holderName: value.holderName,
      } : undefined,
    })
    );
  }, [value, setPayload]);
  
  const options = useMemo(() => ({
    ...stripeStyles(darkMode),
  }), [darkMode]);
  
  const handleChangePaymentFields = (
    paymentElement:
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
      | StripeCardCvcElementChangeEvent
  ) => {
    const updatePaymentElement = (value: boolean) => {
      setValue(prev => ({
        ...prev,
        [paymentElement.elementType]: value,
      }));
    };
    
    if (paymentElement?.error) {
      setFieldErrors((prev) => ({
        ...prev,
        [paymentElement.elementType]: paymentElement?.error?.message || '',
      }));
      updatePaymentElement(false);
    }
    
    if (paymentElement.complete) {
      updatePaymentElement(true);
      setFieldErrors((prev) => ({ ...prev, [paymentElement.elementType]: '' }));
    } else {
      updatePaymentElement(false);
    }
  };
  
  const handleHolderNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(prev => ({
      ...prev,
      holderName: event.target.value,
    }));
  }
  
  return (
    <Grid container spacing={1}>
      {fields.map(field => <PaymentField
        label={field.label}
        error={fieldErrors[field.type]}
        onChange={handleChangePaymentFields}
        options={options}
        key={field.type}
        Component={field.component}
        xs={field.xs}
      />)}
      <Grid item xs={12} className={classes.container}>
        <Typography className={classes.formFieldLabel}>
          Name on card:
        </Typography>
        <TextField
          fullWidth
          onChange={handleHolderNameChange}
          placeholder="J. Smith"
          autoComplete="ccName"
          variant="outlined"
          value={value.holderName}
        />
      </Grid>
    </Grid>
  );
};

export default StripePayment;