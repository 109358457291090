import { Box, CircularProgress, Grid } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import CharityImage from './CharityImage'
import CharityInfo from './CharityInfo'
import CharityTabs from './CharityTabs'
import { BAZU_STATIC_URL } from './env'
import EventHeader from './EventHeader'
import { useEventId, useCharityId } from './location'
import NotFoundPage from './NotFoundPage'
import { cacheEventCharity, EventCharity } from './reducers/eventCharity'
import { Donor } from './types'
import TagContainer from './TagContainer'

function LandingPage() {
  const eventId = useEventId()
  const charityId = useCharityId()
  const dispatch = useDispatch()
  const [loading, setLoading] = React.useState(false)
  const [notFound, setNotFound] = React.useState(false)
  const [donors, setDonors] = React.useState<Donor[]>([])
  
  React.useEffect(() => {
    if (!eventId) {
      setNotFound(true)
      return
    }
    else if (!charityId) {
      window.location.href = `/event/${eventId}`
    }

    setLoading(true)

    Promise.all([
      fetch(BAZU_STATIC_URL + `/event-donations/charity/${charityId}.json`, {
        cache: 'no-cache'
      })
        .then(res => {
          if (res.status !== 200) {
            console.log('status', res.statusText)
            setNotFound(true)
          }
          else return res.json().then((json: EventCharity) => {
            dispatch(cacheEventCharity(json))
          })
        }),
      fetch(BAZU_STATIC_URL + `/event-donors/charity/${charityId}.json`, {
        cache: 'no-cache'
      })
        .then(res => {
          if (res.status !== 200) {
            console.log('status', res.statusText)
            setDonors([])
          }
          else return res.json().then(donors => {
            console.log('donors', donors)
            setDonors(donors)
          })
        })
    ])
      .catch(err => console.error(err))
      .finally(() => setLoading(false))
  }, [dispatch, eventId, charityId, setDonors, setNotFound])

  if (loading) {
    return <CircularProgress style={{ display: 'block', margin: '0 auto' }} />
  }
  else if (notFound) {
    return <NotFoundPage />
  }
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <CharityImage />
        </Grid>
        <Grid item xs={12} md={6}>
          <EventHeader />
          <Box marginTop='20px' />
          <CharityInfo donors={donors} />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <CharityTabs donors={donors} />
        </Grid>
        <Grid item xs={12} md={6} />
      </Grid>
      <TagContainer />
    </>
  )
}

export default LandingPage
