import React from 'react'
import useEventCharity from '../useEventCharity'
import Gtm from './Gtm'

const OrgGtm = () => {

  const { charity } = useEventCharity()
  const [gtmKey, setGtmKey] = React.useState('')
    
  React.useEffect(() => {
    if (charity) {
      if (charity.orgGoogleTagManagerKey) {
        setGtmKey(charity.orgGoogleTagManagerKey)
      }
    }
  }, [charity])

  return (
    <>
      {
        gtmKey
        ? <Gtm gtmKey={gtmKey} />
        : <></>
      }
    </>
  )
}

export default OrgGtm
