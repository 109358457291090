import React from 'react';

function NotFoundPage() {
  return (
    <div>
      Page Not Found.
    </div>
  )
}

export default NotFoundPage