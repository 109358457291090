import React from 'react'
import useEventCharity from '../useEventCharity'
import Gtm from './Gtm'

const EventGtm = () => {

  const { event } = useEventCharity()
  const [gtmKey, setGtmKey] = React.useState('')

  React.useEffect(() => {
    if (event) {
      if (event.eventGoogleTagManagerKey) {
        setGtmKey(event.eventGoogleTagManagerKey)
      }
    }
  }, [event])

  return (
    <>
      {
        gtmKey
        ? <Gtm gtmKey={gtmKey} />
        : <></>
      }
    </>
  )
}

export default EventGtm
