import React from 'react'
import EventGtm from './Tags/EventGtm'
import OrgGtm from './Tags/OrgGtm'
import Tealium from './Tags/Tealium'

const TagContainer = () => {

  return (
    <>
      <EventGtm />
      <OrgGtm />
      <Tealium />
    </>
  )
}

export default TagContainer
