import React from 'react'
import useEventCharity from './useEventCharity'

function CharityImage() {
  const { charity } = useEventCharity()
  if (!charity.imageUrl) return null
  return (
    <img
      alt='charity'
      src={charity.imageUrl}
      style={{ maxWidth: 590, width: '100%' }} />
  )
}

export default CharityImage
