export function validateEmail(address: string) {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(address.toString())
}

export function validatePhoneNumber(value: string) {
  return /^[+]?[0-9]?[\s]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/gm.test(value.toString())
}

export function validateZipCode(value: string) {
  return /^\d{5}$|^\d{5}-\d{4}$/.test(value.toString())
}
