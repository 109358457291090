import React from 'react'
import {
  Card,
  CardContent,
  CardMedia,
  Link,
  Typography,
} from '@mui/material'
import useEventCharity from './useEventCharity';
import { useEventId } from './location';
import { formatDateTime } from './dates';

function EventHeader() {
  const eventId = useEventId()
  const { event } = useEventCharity()
  const eventName = (
    <Typography
      data-cy='event_header_name'
      style={{
        fontSize: 24,
        fontWeight: 300,
        lineHeight: '28px'
      }}
    >
      {event.name}
    </Typography>
  )

  return (
    <Card
      elevation={0}
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginTop: 20
      }}
    >
      <div style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row'
      }}>
        {!event.imageUrl
          ? null
          : <CardMedia
            image={event.imageUrl.replace('-dev', '')}
            style={{
              height: 81,
              minWidth: 81,
              width: 81,
            }}
            title='Event Logo'
          />
        }
        <CardContent>{!event.url
          ? eventName
          : <Link href={event.url} target='_blank'>
            {eventName}
          </Link>
        }
          <Typography
            style={{
              fontSize: 14,
              lineHeight: '16px',
            }}
          >
            {formatDateTime(event.localStartDate)} - {formatDateTime(event.localEndDate)} | {event.hometown}
          </Typography>
          <Typography
            style={{
              fontSize: 14,
              lineHeight: '16px',
            }}
          >
            Event ID: {eventId}
          </Typography>
        </CardContent>
      </div>
    </Card>
  )
}

export default EventHeader
