export const monthsDiff = (from: Date, to: Date) => {
  const months = (to.getFullYear() - from.getFullYear()) * 12 - to.getMonth() + from.getMonth()
  return months <= 1 ? 1 : months
}

export function formatDateTime(dateTime: string | number, hour12 = true) {
  if (!dateTime) return null;

  const options: Intl.DateTimeFormatOptions = {
    dateStyle: 'medium',
    timeStyle: 'short',
    hour12,
  };

  return new Date(dateTime).toLocaleString('en-US', options);
}
