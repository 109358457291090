import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: 'session',
  initialState: { token: '' },
  reducers: {
    cacheToken: (state, action) => {
      state.token = action.payload
    }
  },
})

export const cacheToken = slice.actions.cacheToken

export default slice.reducer
